import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

function HeadingNavigation({ data }) {
  return (
    <div className="resourceHeader">
      <div className="imageContainer">
        <StaticImage src="opsio_contact.webp" alt="opsio_contact" />
      </div>

      <div className="titleContainer">
        <h4>{data.title}</h4>
        <h1>{data.heading}</h1>
      </div>
      <div className="tabContainer">
        <ul className="tabWrapper">
          <Link
            to={`/resource/blog/`}
            key={3}
            className={data.slug === "blog" ? "active" : ""}
          >
            <li>{data.menubar.l3}</li>
          </Link>
          <Link
            to={`/resource/white-papers/`}
            key={4}
            className={data.slug === "white-papers" ? "active" : ""}
          >
            <li>{data.menubar.l4}</li>
          </Link>
          {data.locale === "sv" && (
            <Link
              to={`/resource/press-release/`}
              key={4}
              className={data.slug === "press-release" ? "active" : ""}
            >
              <li>{data.menubar.l5}</li>
            </Link>
          )}
        </ul>
      </div>
    </div>
  );
}

export default HeadingNavigation;
