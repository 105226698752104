import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { graphql, useStaticQuery } from "gatsby";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const url = process.env.GATSBY_API_URL + "/api";
function WhitePaperModal({ visible, handleClose, locale }) {
  const [entries, setEntries] = useState({ name: "", phone: "", email: "" });
  const [success, setSuccess] = useState(false);
  const [nameErr, setNameErr] = useState();
  const [emailErr, setEmailErr] = useState();
  const [numberErr, setNumberErr] = useState();
  const [checkboxStatus, setCheckboxStatus] = useState(false);
  const [checkboxOptionalStatus, setCheckboxOptionalStatus] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState("No");
  const data = useStaticQuery(graphql`
    query {
      allStrapiWhitepaperModal {
        edges {
          node {
            locale
            des
            email
            heading
            btn
            title
            number
          }
        }
      }

      allStrapiContact {
        edges {
          node {
            locale
            checkboxText1
            checkboxText2
          }
        }
      }
    }
  `);
  const getData = data.allStrapiWhitepaperModal.edges.filter(
    (node) => node.node.locale === locale
  );
  const getCheckboxData = data.allStrapiContact.edges.filter(
    (node) => node.node.locale === locale
  );

  let count = 0;
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (entries.email === "") {
      count = count + 1;
      setEmailErr("*Enter Your Email");
    }
    if (entries.name === "") {
      count = count + 1;
      setNameErr("*Enter Your Name");
    }
    if (entries.name !== "") {
      var regName = /^[A-Za-z\s]+$/;
      if (!regName.test(entries.name)) {
        count = count + 1;
        setNameErr("Please enter your Valid full name");
      } else {
        setNameErr("");
      }
    }
    if (entries.email !== "") {
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (entries.email.match(mailformat)) {
        setEmailErr("");
      } else {
        count = count + 1;
        setEmailErr("*Enter Your Valid Email");
      }
    }
    if (entries.phone !== "") {
      setNumberErr("");
    }
    if (entries.phone === "" || entries.phone.length < 8) {
      count = count + 1;
      setNumberErr("*Enter Correct Phone Number");
    }
    if (count === 0) {
      setNumberErr();
      setEmailErr();
      setNameErr();

      try {
        const data = {
          name: entries.name,
          number: entries.phone,
          email: entries.email,
          marketingConsent,
          source:
            process.env.GATSBY_LANG === "en"
              ? "opsiocloud.com"
              : process.env.GATSBY_LANG === "sv"
              ? "opsio.se"
              : "opsio.in",
        };
        var formdata = new FormData();
        formdata.append("data", JSON.stringify(data));
        await Promise.all([
          axios.post(`${url}/whitepaper-response-lists`, formdata),
          axios.post(`${url}/email-whitepaper`, {
            ...data,
            phone: data.number,
          }),
        ]);
        setSuccess(true);
      } catch (e) {
        setSuccess(false);
      }

      setEntries({
        name: "",
        phone: "",
        email: "",
      });
    }
  };
  useEffect(() => {
    setTimer(3000);
  }, [success]);
  const setTimer = (delay) => {
    setTimeout(() => setSuccess(false), delay);
  };

  return (
    <Modal
      show={visible}
      onHide={handleClose}
      animation={false}
      centered={true}
      size="lg"
    >
      <Modal.Body style={{ backgroundColor: "white", paddingTop: 0 }}>
        <Modal.Header
          className="whitepaper-modal-header"
          style={{ border: "none" }}
          closeButton
          closeLabel=""
        ></Modal.Header>
        <div className="modalTitle">{getData[0].node.phonetitle}</div>
        <div className="modalHeading">{getData[0].node.heading}</div>
        <div className="modalDes">{getData[0].node.des}</div>
        <div className="outer_whitepaper">
          <div className="modalinputnamediv">
            <div>
              <input
                type="text"
                className="modalinputname"
                value={entries.name}
                placeholder="Enter Your Name"
                onChange={(e) =>
                  setEntries({ ...entries, name: e.target.value })
                }
              ></input>
              <p style={{ color: "red" }}>{nameErr}</p>
            </div>
          </div>

          <div className="modalinputcontactdiv">
            <div className="modalinputcontact">
              <PhoneInput
                value={entries.phone}
                onChange={(e) => setEntries({ ...entries, phone: e })}
                country={
                  process.env.GATSBY_LANG === "en"
                    ? "us"
                    : process.env.GATSBY_LANG === "ar-AE"
                    ? "ae"
                    : "in"
                }
                placeholder={getData[0].node.number}
              />
              <p style={{ color: "red" }}>{numberErr}</p>
            </div>
            <div className="modalinputemail">
              <input
                type="text"
                className="modalinputcontact"
                placeholder={getData[0].node.email}
                value={entries.email}
                onChange={(e) =>
                  setEntries({ ...entries, email: e.target.value })
                }
              />
              <p className="error_msg_popup" style={{ color: "red" }}>
                {emailErr}
              </p>
            </div>
          </div>
          <div className="  my-3">
            <div className="desktopViewCheckbox">
              <input
                type="checkbox"
                onChange={() => setCheckboxStatus(!checkboxStatus)}
                checked={checkboxStatus}
                id="policy"
                className="m-0"
              />
              <label
                htmlFor="#policy"
                className="text-start"
                dangerouslySetInnerHTML={{
                  __html: getCheckboxData[0].node.checkboxText1,
                }}
              />
            </div>
            <div className="desktopViewCheckbox">
              <input
                type="checkbox"
                checked={marketingConsent === "Yes" ? true : false}
                onChange={(e) => {
                  setCheckboxOptionalStatus(e.target.checked);
                  setMarketingConsent(e.target.checked ? "Yes" : "No");
                }}
                id="policy"
                className="m-0"
              />
              <label htmlFor="#policy" className="text-start">
                {getCheckboxData[0].node.checkboxText2}
              </label>
            </div>
            <div className="mobileViewCheckbox">
              <input
                type="checkbox"
                onChange={() => setCheckboxStatus(!checkboxStatus)}
                checked={checkboxStatus}
                id="policy"
                className="m-0"
              />
              <label
                htmlFor="#policy"
                className="text-start"
                dangerouslySetInnerHTML={{
                  __html: getCheckboxData[0].node.checkboxText1,
                }}
              />
            </div>
            <div className="mobileViewCheckbox">
              <input
                type="checkbox"
                checked={marketingConsent === "Yes" ? true : false}
                onChange={(e) => {
                  setCheckboxOptionalStatus(e.target.checked);
                  setMarketingConsent(e.target.checked ? "Yes" : "No");
                }}
                id="policy"
                className="m-0"
              />
              <label htmlFor="#policy" className="text-start">
                {getCheckboxData[0].node.checkboxText2}
              </label>
            </div>
          </div>
        </div>
        {success && (
          <div
            className="text-center sent_message"
            style={{ color: "#0ED39B" }}
          >
            White Paper will be sent to your e-mail id.
          </div>
        )}
        <div className="submitbtn">
          <button
            className={`whitepapermodalBtn  ${
              !checkboxStatus ? "disabled" : ""
            }`}
            onClick={handleSubmit}
            disabled={!checkboxStatus}
          >
            {getData[0].node.btn}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default WhitePaperModal;
